import navigation from '@/assets/front/navigation.png'
import storage from '@/assets/front/storage.png'
import setting from '@/assets/front/setting.png'

const documentData = [
  {
    type: '产研',
    describe: 'API、消息、对接方案的发布、维护，成员管理、资产交接等',
    img: navigation,
    moreLink: 'https://joyspace.jd.com/teams/ptJ4GlBa9EQz0XL8vLiNF/fz3RgEMlljwX0CnQBRl7',
    list: [
      {
        title: '【整体介绍】产研',
        link: 'https://joyspace.jd.com/pages/0V8fUW5zdUiG09NW49a7',
        hot: true,
      },
      {
        title: '【资源发布】JSF服务API',
        link: 'https://joyspace.jd.com/pages/pwfvHt2wlz7nTlGbhVCN',
        hot: true,
      },
      {
        title: '【资源发布】HTTP服务API',
        link: 'https://joyspace.jd.com/pages/6CVNAKGCDZpv2Y6R77G6',
      },
      {
        title: '【资源发布】回传API',
        link: 'https://joyspace.jd.com/pages/t9LD4c0jDCxaafvPHC29',
      },
      {
        title: '【资源发布】消息TOPIC',
        link: 'https://joyspace.jd.com/pages/3GeoB8XJX2JCHx10ogxG',
      },
      {
        title: '【附录A】调用环境说明',
        link: 'https://joyspace.jd.com/pages/eLaam30m65wBR8gfq37T',
      },
      {
        title: '【附录B】客制化系统对接手册',
        link: 'https://joyspace.jd.com/pages/tLrgmceUtWj33fj9FP4n',
      },
      {
        title: '【附录C】文件上传/下载',
        link: 'https://joyspace.jd.com/pages/AQoztEUMydm1Z4Z7bBCn',
      },
      {

      },
      {

      }

    ]
  },
  {
    type: '系统实施',
    describe: '开放平台入驻、接口调用方法、运维工具使用方法 ',
    img: setting,
    moreLink: 'https://joyspace.jd.com/teams/ptJ4GlBa9EQz0XL8vLiNF/G6Xv08GoYP7uQzYGby2r',
    list: [
      {
        title: '【整体介绍】系统实施',
        link: 'https://joyspace.jd.com/pages/rmSMQx16ThnP16jyW7KR',
        hot: true
      },
      {
        title: '【附录A】运维工具',
        link: 'https://joyspace.jd.com/pages/XWKWHBSkdkkXbXKJheay',
      },
      {
        title: '【附录B】IT工单提报流程',
        link: 'https://joyspace.jd.com/pages/IVvDkhLYxUJMoIZBCelI',
      }
    ]
  },
  {
    type: 'IT',
    describe: '日常运维工作介绍',
    img: storage,
    moreLink: 'https://joyspace.jd.com/teams/ptJ4GlBa9EQz0XL8vLiNF/ICJTNwPVQ1afjLdg2twA',
    list: [
      {
        title: '【整体介绍】IT',
        link: 'https://joyspace.jd.com/pages/HQcwaUTsWXYJtyERtQst',
      }
    ]
  }
]

export {
  documentData
}
