<template>
  <div class="document-wrap">
    <div class="document-wrap-header">
      <div class="title">文档中心</div>
      <div class="describe">开放平台入站必看，提供全面的技术文档、丰富的开发工具和资源，助力开发者能力成长</div>
    </div>
    <div class="document-warp-context">
      <documentContent v-for="item in documentData" :key="item.title" :img="item.img" :contentData="item"/>
    </div>
  </div>
</template>
<script>
import documentContent from './documentContent.vue'
import { documentData } from './data.js'

export default {
  components: {
    documentContent,
  },
  data() {
    return {
      documentData
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/mixin.scss";
.document-wrap {
  padding-bottom: 176px;
  background-image: url("~@/assets/front/document-bg.png");
  background-size: cover;
  display: grid;
  justify-content: center;
  // grid-template-rows: 254px auto;
  .document-wrap-header {
    height: 89px;
    text-align: center;
    margin-bottom: 65px;
    margin-top: 164px;
    display: inline-block;

    .title {
      color: #23252B;
      font-size: 36px;
      line-height: 40px;
      @include font-family-jd;
      margin-bottom: 16px;

    }
    .describe {
      color: #525765;
      font-size: 18px;
      line-height: 32px;
    }
  }
    .document-warp-context {
      width: 1080px;
      display: grid;
      grid-template-columns: repeat(3,1fr);
    }
}
</style>
