<template>
  <div class="document-content">
    <div class="document-content-card">
      <div class="header-icon" :style="{ 'background-image': `url(${img})`}"></div>
      <div class="header-wrap">
        <div class="title">{{ contentData.type }}</div>
        <div class="document-nums">{{ list.length}}篇</div>
        <div class="document-describe">{{ contentData.describe }}</div>
      </div>
      <div class="document-charts">
        <div class="chart-list">
          <div class="ranking" v-for="(item,index) in list" :key="index">
            <span class="rank-index" v-if="item.title">{{index + 1}}</span>
            <span class="rank-title" @click="openDefaultLink(item.link)">{{ item.title }}</span>
            <span class="rank-tag" v-if="item.hot"></span>
          </div>
        </div>
      </div>
      <div class="document-footer-btn" @click="openDefaultLink(contentData.moreLink)">
        查看更多
        <span class="footer-icon"></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    img: {
      type: String,
      default: null,
    },
    contentData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      list: []
    }
  },
  mounted() {
    const { list } = this.contentData
    this.list = list
  },
  methods: {
    openDefaultLink(link) {
      if (link) {
        window.open(link, '_blank')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/mixin.scss";
.document-content {
  width: 340px;
  height: 440px;
  background-image: url("~@/assets/front/document-content-bg.png");
  background-size: 340px 440px;
  position: relative;
  .document-content-card {

    box-sizing: border-box;
    padding: 24px;

    .header-icon {
      width: 90px;
      height: 90px;
      background-size: cover;
      position: absolute;
      // Look: 待UI给出正确切图
      top: -20px;
      right: 0;
    }
    .header-wrap {
      .title {
        color: #23252B;
        font-size: 24px;
        line-height: 18px;
        @include font-family-jd;
        display: inline-block;
      }
      .document-nums {
        color: #525765;
        font-size: 16px;
        line-height: 16px;
        @include font-family-jd;
        margin-left: 12px;
        display: inline-block;
      }
      .document-describe {
        width: 210px;
        color: #525765;
        opacity: 0.7;
        font-size: 14px;
        line-height: 24px;
        margin-top: 12px;
      }
    }
    .document-charts {
      margin-top: 20px;
      width: 250px;
      .chart-list {
        .ranking {
          margin-bottom: 12px;
          cursor: pointer;
          &:nth-child(1) {
          .rank-index {
              color: #FC3737;
            }
          }
          &:nth-child(2) {
            .rank-index {
              color: #ADA17F;
            }
          }
          &:nth-child(3) {
            .rank-index {
              color: #A36049;
            }
          }
          .rank-index {
            color: #545967;
            margin-right: 12px;
          }
          .rank-title {
            font-size: 14px;
            line-height: 20px;
            color: #525765;
          }
          .rank-tag {
            width: 27px;
            height: 15px;
            margin-left: 8px;
            display: inline-block;
            background-size: cover;
            background-image: url("~@/assets/front/tag.png");
          }
          &:hover {
            .rank-title, .rank-index {
              color: #3C6EF0;
            }
          }
        }
      }
    }
    .document-footer-btn {
      color: #868D9F;
      left: 24px;
      bottom: 25px;
      position: absolute;
      cursor: pointer;
      .footer-icon {
        width: 20px;
        height: 20px;
        display: inline-block;
        vertical-align: top;
        background-image: url("~@/assets/front/Lui-icon-arrow-right-min.png");
        background-size: cover;
      }
      &:hover {
        color: #3C6EF0;
        .footer-icon {
          background-image: url("~@/assets/front/Lui-icon-arrow-right-min-active.png");
        }
      }
    }
  }
}
</style>
